import axios from 'axios';
import { constants } from 'constants/constants';

const authService = {
  login: async (email, password) => {
    try {
      const response = await axios.post(`${constants.apiUrl}/login`, { email, password }, { withCredentials: true });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};

export default authService;
