import React, { useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  Box,
  FormHelperText,
  FormControl,
  FormLabel
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
// Custom components
import Card from "components/card/Card";

export default function ItemTable({orderItems}) {
  const columnsData = [
    {
        Header: "SKU",
        accessor: "SKU",
    },
    {
        Header: "Weight",
        accessor: "weight",
    },
    {
        Header: "Product Description",
        accessor: "productDescription",
    },
    {
        Header: "Quantity",
        accessor: "Quantity",
    },
    {
        Header: "Enter Product Description",
        accessor: "inputProductDescription",
    },
    {
      Header: "Enter Cost of single piece",
      accessor: "inputCostPerPiece",
    },
    {
      Header: "Total Cost of Product",
      accessor: "totalCostProduct",
  }
];
const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  // Inside your component
const [selectedFiles, setSelectedFiles] = useState([]);

const handleFileChange = (event) => {
  const newSelectedFiles = Array.from(event.target.files);
  setSelectedFiles(newSelectedFiles);
};

  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => orderItems, [orderItems]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
    manualPagination: true,
    pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      borderRadius="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
        <Flex direction="column" w="50%" px="0px" ml={4}>
      {/* Upload Section */}
      <FormControl>
        <FormLabel htmlFor="fileUpload">Upload Purchase Invoice</FormLabel>
        <Input type="file" id="fileUpload" accept="image/*,.pdf" multiple onChange={handleFileChange} />
        <FormHelperText>Select one or more images or PDFs</FormHelperText>
      </FormControl>
       {/* Selected Files Section */}
        {selectedFiles.length > 0 && (
        <Flex direction="column" w="50%" px="0px" ml={4} mt={4}>
            <Text fontWeight="bold">Selected Files:</Text>
            <Box>
            {selectedFiles.map((file, index) => (
                <Flex key={index} alignItems="center">
                <Icon as={IoDocumentTextOutline} color="gray.500" mr={2} />
                <Text>{file.name}</Text>
                </Flex>
            ))}
            </Box>
        </Flex>
        )}
        </Flex>
   
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            style={{ overflowX: "auto" }}
          >
            <Thead >
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <Tr>
                <Td colSpan={columnsData.length} textAlign="center">
                  No data available.
                </Td>
              </Tr>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let cellContent = cell.value;

                      if (cell.column.Header === "View") {
                        cellContent = (
                          <Link
                            to={`/support/view-job?id=${cell.value}`} // Change this route to match your actual route
                            style={{
                              display: "inline-block",
                              padding: "6px 12px",
                              backgroundColor: "green",
                              color: "white",
                              textDecoration: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                          >
                            View
                          </Link>

                        );
                      } else {
                        cellContent = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          maxH="30px !important"
                          py="8px"
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {cellContent}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            )}
          </Tbody>
          </Table>
        </Flex>
     
    </Card>
  );
}
