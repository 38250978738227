import React, { useState, useEffect } from "react";
import {
    Box,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Button,

    Flex,
    useToast,
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import axios from "axios";
import Select from 'react-select';
import { useHistory } from 'react-router-dom';


export default function EditClient() {
    const [formData, setFormData] = useState({
        SenderCompanyName: '',
        SenderAddress: '',
        SenderAddress2: '',
        SenderCityName: '',
        SenderZipCode: '',
        SenderState: '',
        SenderCountry: '',
        SenderGSTIN: '',
        ClientRateCard: '',
        client_bpost_rate_card	: '',
        client_sweden_rate_card: ''
    });

    const toast = useToast();

    const getQueryParams = (url) => {
        const queryString = url.split("?")[1];
        if (!queryString) {
            return {};
        }

        const paramsArray = queryString.split("&");
        const params = {};

        paramsArray.forEach(param => {
            const [key, value] = param.split("=");
            params[key] = decodeURIComponent(value);
        });

        return params;
    };

    const windowUrl = window.location.href;

 

    const history = useHistory();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {

        // Check if any field is empty
        const isEmptyField = Object.values(formData).some(value => value === '');
        if (isEmptyField) {
            toast({
                title: 'Error',
                description: 'Please fill in all fields',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const queryParams = getQueryParams(windowUrl);
        const client_id = queryParams["client"];

        axios
            .post(`${constants.apiUrl}/admin/update-client?client_id=${client_id}`, formData, {headers}) // Make a POST request using Axios
            .then((response) => {

                if (response.status === 200) {
                  
                    toast({
                        position: 'bottom-right',
                        title: 'Client Updated.',
                
                        status: "success",
                        duration: 3000, // Duration in milliseconds
                        isClosable: true, // Whether the toast can be closed by the user
                    });

                    history.push('/admin/customer-list');

                }
            })
            .catch((error) => {
                console.error("Error updating client :", error);
                if (error.response.status === 409) {
                    toast({
                        position: 'bottom-right',
                        title: "Client with the same email already exists",
                        status: "info",
                        duration: 3000, // Duration in milliseconds
                        isClosable: true, // Whether the toast can be closed by the user
                    });

                } else {
                    toast({
                        position: 'bottom-right',
                        title: "Error updating Client" + error,
                        status: "error",
                        duration: 3000, // Duration in milliseconds
                        isClosable: true, // Whether the toast can be closed by the user
                    });
                }

            });

    };

     // Fetch client data on component mount based on client_id
     useEffect(() => {
        const queryParams = getQueryParams(windowUrl);
        const client_id = queryParams["client"];
        if (client_id) {
            fetchClientData(client_id);
        }
    }, []);

    // Function to fetch client data based on client_id
    const fetchClientData = async (client_id) => {
        try {
            const token = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const response = await axios.get(`${constants.apiUrl}/admin/fetch-client-data?client=${client_id}`, { headers });
            const clientData = response.data;
            setFormData(clientData);
             // Check if the 'client_rate_card' field exists in the response
        if ('client_rate_card' in clientData) {
            // If it exists, update the formData state with its value
            setFormData(prevState => ({
                ...prevState,
                ClientRateCard: clientData.client_rate_card
            }));
        } else {
            console.error("Error: 'client_rate_card' field not found in the API response");
            // Handle the scenario where 'client_rate_card' field is missing in the API response
        }
        } catch (error) {
            console.error("Error fetching client data:", error);
            // Handle error if necessary
        }
    };


    const clientRates = [
        { value: "DHRITEE", label: "DHRITEE" },
        { value: "PACKIVA", label: "PACKIVA" },
        { value: "OTHERS", label: "OTHERS" },
    ]

    const bpostClientRates = [
        { value: "ALL", label: "ALL" },
      
    ];

    const swedenClientRates = [
        { value: "ALL", label: "ALL" },
      
    ];

    return (
        <Box
            pt={{ base: "130px", md: "80px", xl: "80px" }}
            backgroundColor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <SimpleGrid columns={1} gap="20px" mb="20px" w="50%">
                {/* SenderCompanyName */}
                <FormControl>
                    <FormLabel>Sender Company Name</FormLabel>
                    <Input name="SenderCompanyName" placeholder="Sender Company Name" value={formData.SenderCompanyName} onChange={handleChange} required />
                </FormControl>

                {/* SenderAddress */}
                <FormControl>
                    <FormLabel>Sender Address</FormLabel>
                    <Input name="SenderAddress" placeholder="Sender Address" value={formData.SenderAddress} onChange={handleChange} required />
                </FormControl>

                {/* SenderAddress2 */}
                <FormControl>
                    <FormLabel>Sender Address 2</FormLabel>
                    <Input name="SenderAddress2" placeholder="Sender Address 2" value={formData.SenderAddress2} onChange={handleChange} required />
                </FormControl>

                {/* SenderCityName */}
                <FormControl>
                    <FormLabel>Sender City Name</FormLabel>
                    <Input name="SenderCityName" placeholder="Sender City Name" value={formData.SenderCityName} onChange={handleChange} required />
                </FormControl>

                {/* SenderZipCode */}
                <FormControl>
                    <FormLabel>Sender Zip Code</FormLabel>
                    <Input name="SenderZipCode" placeholder="Sender Zip Code" value={formData.SenderZipCode} onChange={handleChange} required />
                </FormControl>

                {/* SenderState */}
                <FormControl>
                    <FormLabel>Sender State</FormLabel>
                    <Input name="SenderState" placeholder="Sender State" value={formData.SenderState} onChange={handleChange} required />
                </FormControl>

                {/* SenderCountry */}
                <FormControl>
                    <FormLabel>Sender Country</FormLabel>
                    <Input name="SenderCountry" placeholder="Sender Country" value={formData.SenderCountry} onChange={handleChange} required />
                </FormControl>

                {/* SenderGSTIN */}
                <FormControl>
                    <FormLabel>Sender GSTIN</FormLabel>
                    <Input name="SenderGSTIN" placeholder="Sender GSTIN" value={formData.SenderGSTIN} onChange={handleChange} required />
                </FormControl>

                <FormControl>
                    <FormLabel>Select Emirates Client Rates</FormLabel>
                    <Select
                        value={clientRates.find(client => client.value === formData.ClientRateCard)} // Set the selected value based on the formData
                        options={clientRates.map(client => ({ value: client.value, label: client.label }))}
                        onChange={option => setFormData(prevState => ({
                            ...prevState,
                            ClientRateCard: option.value, // Update the ClientRateCard field with the selected value
                        }))}
                    />

                </FormControl>

                <FormControl>
                    <FormLabel>Select BPOST Client Rates</FormLabel>
                    <Select
                        value={bpostClientRates.find(client => client.value === formData.client_bpost_rate_card)} // Set the selected value based on the formData
                        options={bpostClientRates.map(client => ({ value: client.value, label: client.label }))}
                        onChange={option => setFormData(prevState => ({
                            ...prevState,
                            client_bpost_rate_card: option.value, // Update the ClientRateCard field with the selected value
                        }))}
                    />

                </FormControl>

                <FormControl>
                    <FormLabel>Select Sweden Client Rates</FormLabel>
                    <Select
                        value={swedenClientRates.find(client => client.value === formData.client_sweden_rate_card)} // Set the selected value based on the formData
                        options={swedenClientRates.map(client => ({ value: client.value, label: client.label }))}
                        onChange={option => setFormData(prevState => ({
                            ...prevState,
                            client_sweden_rate_card: option.value, // Update the ClientRateCard field with the selected value
                        }))}
                    />

                </FormControl>

                {/* Submit Button */}
                <Button variant="brand" size="md" onClick={handleSubmit}>Update Client</Button>
            </SimpleGrid>
        </Box>
    );
}


