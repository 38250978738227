import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { constants } from "constants/constants";


function UploadManifest() {
  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const handleFileUpload = (event) => {
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("manifestFile", file);

      setIsLoading(true);

      axios.post(`${constants.apiUrl}/admin/upload-manifest-starlinks`, formData, {headers, withCredentials: true})
        .then((response) => {
          setIsLoading(false);
          toast({
            position: 'bottom-right',
            title: 'Manifest Uploaded Successfully',
            status: "success",
            duration: 3000, // Duration in milliseconds
            isClosable: true, // Whether the toast can be closed by the user
          });
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Manifest xlsx upload error:", error);
          toast({
            position: 'bottom-right',
            title: 'Manifest0 upload failed. Please check your API or retry later.',
            status: "error",
            duration: 3000, // Duration in milliseconds
            isClosable: true, // Whether the toast can be closed by the user
          });
        });
    }
  };


  return (
    isLoading ? (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" thickness="4px" color="green.500" />
      </Flex>
    ) : (
      <Box>
        <Heading as="h1" mb={6} mt={6}>
          Manifest Upload
        </Heading>
        <Flex flexDirection="row" alignItems="center">
          <FormControl flex="1">
            <FormLabel>Upload CSV File</FormLabel>
            <Input type="file" accept=".xlsx" onChange={handleFileUpload} />
          </FormControl>
        </Flex>
      </Box>
    )
  );
  
}

export default UploadManifest;
