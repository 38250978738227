/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports

import React, { useState, useEffect } from "react";
import {
    Box,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Button,

    Flex,
    useToast,
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import axios from "axios";
import Select from 'react-select';
import { useHistory } from 'react-router-dom';


export default function AddClient() {
    const [formData, setFormData] = useState({
        SenderCompanyName: '',
        SenderAddress: '',
        SenderAddress2: '',
        SenderCityName: '',
        SenderZipCode: '',
        SenderState: '',
        SenderCountry: '',
        SenderGSTIN: '',
        ClientRateCard: '',
        client_bpost_rate_card	: '',
        client_sweden_rate_card: ''
    });

    const toast = useToast();
    const history = useHistory();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {

        // Check if any field is empty
        const isEmptyField = Object.values(formData).some(value => value === '');
        if (isEmptyField) {
            toast({
                title: 'Error',
                description: 'Please fill in all fields',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
  
        axios
            .post(`${constants.apiUrl}/admin/add-client`, formData, {headers}) // Make a POST request using Axios
            .then((response) => {

                if (response.status === 200) {
                  
                    toast({
                        position: 'bottom-right',
                        title: 'Client created.',
                
                        status: "success",
                        duration: 3000, // Duration in milliseconds
                        isClosable: true, // Whether the toast can be closed by the user
                    });

                    history.push('/admin/customer-list');

                }
            })
            .catch((error) => {
                console.error("Error creating cclient :", error);
                if (error.response.status === 409) {
                    toast({
                        position: 'bottom-right',
                        title: "Client with the same email already exists",
                        status: "info",
                        duration: 3000, // Duration in milliseconds
                        isClosable: true, // Whether the toast can be closed by the user
                    });

                } else {
                    toast({
                        position: 'bottom-right',
                        title: "Error creating Client" + error,
                        status: "error",
                        duration: 3000, // Duration in milliseconds
                        isClosable: true, // Whether the toast can be closed by the user
                    });
                }

            });

    };

    const clientRates = [
        { value: "DHRITEE", label: "DHRITEE" },
        { value: "PACKIVA", label: "PACKIVA" },
        { value: "OTHERS", label: "OTHERS" },
    ];

    const bpostClientRates = [
        { value: "ALL", label: "ALL" },
      
    ];

    const swedenClientRates = [
        { value: "ALL", label: "ALL" },
      
    ];

    return (
        <Box
            pt={{ base: "130px", md: "80px", xl: "80px" }}
            backgroundColor="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <SimpleGrid columns={1} gap="20px" mb="20px" w="50%">
                {/* SenderCompanyName */}
                <FormControl>
                    <FormLabel>Sender Company Name</FormLabel>
                    <Input name="SenderCompanyName" placeholder="Sender Company Name" value={formData.SenderCompanyName} onChange={handleChange} required />
                </FormControl>

                {/* SenderAddress */}
                <FormControl>
                    <FormLabel>Sender Address</FormLabel>
                    <Input name="SenderAddress" placeholder="Sender Address" value={formData.SenderAddress} onChange={handleChange} required />
                </FormControl>

                {/* SenderAddress2 */}
                <FormControl>
                    <FormLabel>Sender Address 2</FormLabel>
                    <Input name="SenderAddress2" placeholder="Sender Address 2" value={formData.SenderAddress2} onChange={handleChange} required />
                </FormControl>

                {/* SenderCityName */}
                <FormControl>
                    <FormLabel>Sender City Name</FormLabel>
                    <Input name="SenderCityName" placeholder="Sender City Name" value={formData.SenderCityName} onChange={handleChange} required />
                </FormControl>

                {/* SenderZipCode */}
                <FormControl>
                    <FormLabel>Sender Zip Code</FormLabel>
                    <Input name="SenderZipCode" placeholder="Sender Zip Code" value={formData.SenderZipCode} onChange={handleChange} required />
                </FormControl>

                {/* SenderState */}
                <FormControl>
                    <FormLabel>Sender State</FormLabel>
                    <Input name="SenderState" placeholder="Sender State" value={formData.SenderState} onChange={handleChange} required />
                </FormControl>

                {/* SenderCountry */}
                <FormControl>
                    <FormLabel>Sender Country</FormLabel>
                    <Input name="SenderCountry" placeholder="Sender Country" value={formData.SenderCountry} onChange={handleChange} required />
                </FormControl>

                {/* SenderGSTIN */}
                <FormControl>
                    <FormLabel>Sender GSTIN</FormLabel>
                    <Input name="SenderGSTIN" placeholder="Sender GSTIN" value={formData.SenderGSTIN} onChange={handleChange} required />
                </FormControl>

                <FormControl>
                    <FormLabel>Select Emirates Client Rates</FormLabel>
                    <Select
                        value={clientRates.find(client => client.value === formData.ClientRateCard)} // Set the selected value based on the formData
                        options={clientRates.map(client => ({ value: client.value, label: client.label }))}
                        onChange={option => setFormData(prevState => ({
                            ...prevState,
                            ClientRateCard: option.value, // Update the ClientRateCard field with the selected value
                        }))}
                    />

                </FormControl>

                <FormControl>
                    <FormLabel>Select BPOST Client Rates</FormLabel>
                    <Select
                        value={bpostClientRates.find(client => client.value === formData.client_bpost_rate_card)} // Set the selected value based on the formData
                        options={bpostClientRates.map(client => ({ value: client.value, label: client.label }))}
                        onChange={option => setFormData(prevState => ({
                            ...prevState,
                            client_bpost_rate_card: option.value, // Update the ClientRateCard field with the selected value
                        }))}
                    />

                </FormControl>

                <FormControl>
                    <FormLabel>Select Sweden Client Rates</FormLabel>
                    <Select
                        value={swedenClientRates.find(client => client.value === formData.client_sweden_rate_card)} // Set the selected value based on the formData
                        options={swedenClientRates.map(client => ({ value: client.value, label: client.label }))}
                        onChange={option => setFormData(prevState => ({
                            ...prevState,
                            client_sweden_rate_card: option.value, // Update the ClientRateCard field with the selected value
                        }))}
                    />

                </FormControl>

                {/* Submit Button */}
                <Button variant="brand" size="md" onClick={handleSubmit}>Submit</Button>
            </SimpleGrid>
        </Box>
    );
}


