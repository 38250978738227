import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Textarea,
    Input,
    useToast,
    Select,
    Switch,
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import {useHistory } from "react-router-dom";
import DatePicker from "react-datepicker"; // Import DatePicker from react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles
import { format } from 'date-fns';


function CreateTask() {
    const [ticketNumber, setTicketNumber] = useState("");
    const [taskStatus, setTaskStatus] = useState("");
    const [warehouse, setWarehouse] = useState("");
    const [department, setDepartment] = useState("");
    const [client, setClient] = useState("");
    const [taskType, setTaskType] = useState("");
    const [billingType, setBillingType] = useState("");
    const [taskName, setTaskName] = useState("");
    const [taskDescription, setTaskDescription] = useState("");
    const [numberOfUnits, setNumberOfUnits] = useState("");
    const [reEnterNumberOfUnits, setReEnterNumberOfUnits] = useState("");
    const [billingRate, setBillingRate] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [isBillable, setIsBillable] = useState(false);
    const [jobPriority, setJobPriority] = useState("");

    const [warehouseData, setWarehouseData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [taskTypeData, setTaskTypeData] = useState([]);
    const [billingTypeData, setBillingTypeData] = useState([]);
    const [jobPriorityData, setJobPriorityData] = useState([]);
    const toast = useToast();
    const history = useHistory();
    // Fetch data for dropdowns (example API endpoints)
    useEffect(() => {
        const token = localStorage.getItem("accessToken");
      
        const headers = {
          Authorization: `Bearer ${token}`,
        };
      
        // An array of Axios requests
        const axiosRequests = [
          axios.get(`${constants.apiUrl}/support/warehouse`, { headers, withCredentials: true }),
          axios.get(`${constants.apiUrl}/support/department`, { headers, withCredentials: true }),
          axios.get(`${constants.apiUrl}/support/client`, { headers, withCredentials: true }),
          axios.get(`${constants.apiUrl}/support/taskType`, { headers, withCredentials: true }),
          axios.get(`${constants.apiUrl}/support/billingType`, { headers, withCredentials: true }),
          axios.get(`${constants.apiUrl}/support/jobPriority`, { headers, withCredentials: true }),
        ];
      
        // Use Promise.all to execute all requests and handle errors
        Promise.all(axiosRequests.map(request => {
          return request.catch(error => {
            console.error("Error fetching data:", error);
            return { data: null }; // Return an object with data: null to indicate the error
          });
        })).then(responses => {
          // Process responses to set state
          setWarehouseData(responses[0].data);
          setDepartmentData(responses[1].data);
          setClientData(responses[2].data);
          setTaskTypeData(responses[3].data);
          setBillingTypeData(responses[4].data);
          setJobPriorityData(responses[5].data);
        });
      
      }, []);
      
      

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (numberOfUnits !== reEnterNumberOfUnits) {
            toast({
              position: "bottom-right",
              title: "Number of Units do not match",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
        // Call the API to create a new task with the provided data
        const date = new Date();
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
          timeZone: 'Europe/London',
        };
        
        const formattedDate = date.toLocaleString('en-US', options);
        
       const parsedDeliveryDate = format(deliveryDate, 'MM-dd-yyyy');
        
        const formData = {
            ticketNumber,
            taskStatus,
            warehouse,
            department,
            client,
            taskType,
            billingType,
            taskName,
            taskDescription,
            numberOfUnits,
            reEnterNumberOfUnits,
            billingRate,
            isBillable,
            creationDate: formattedDate,
            jobPriority,
            deliveryDate: parsedDeliveryDate
        };
        const token = localStorage.getItem("accessToken");
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        axios.post(`${constants.apiUrl}/support/createTask`, formData, {headers, withCredentials: true}).then((response) => {
            if (response.status === 201) {
              toast({
                position: "bottom-right",
                title: "Job Created successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              history.push(`/support/view-job?id=${response.data.id}`);
            } else {
              toast({
                position: "bottom-right",
                title: "Error Creating Job, Please try again !",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
        });

      
    };

    return (
        <Box p={6}>
            <Heading mb={4} size="lg">
                Create Task
            </Heading>
            <form onSubmit={handleFormSubmit}>
                <Flex direction={{ base: "column", md: "row" }} gridGap="20px">
                    <FormControl mb={4}>
                        <FormLabel>Freshdesk Ticket Number</FormLabel>
                        <Input
                            type="number"
                            value={ticketNumber}
                            onChange={(e) => setTicketNumber(e.target.value)}
                            required
                            variant="brand"
                        />
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Task Status</FormLabel>
                        <Select
                            value={taskStatus}
                            onChange={(e) => setTaskStatus(e.target.value)}
                            variant="brand"
                            required
                        >
                            <option value="">Select</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Ready">Ready</option>
                            {/* Populate dropdown options */}
                        </Select>
                    </FormControl>
                    {/* Add more dropdowns as needed for warehouse, department, client, task type, and billing type */}
                    <FormControl>
                        <FormLabel>Warehouse</FormLabel>
                        <Select
                            value={warehouse}
                            onChange={(e) => setWarehouse(e.target.value)}
                            variant="brand"
                            required
                        >
                            <option value="">Select</option>
                            {warehouseData.map((warehouse) => (
                                <option key={warehouse.warehouse_id} value={warehouse.warehouse_id}>
                                    {warehouse.warehouse_id}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Flex>
                <Flex direction={{ base: "column", md: "row" }} gridGap="20px">
                    <FormControl mb={4}>
                        <FormLabel>Department</FormLabel>
                        <Select
                            value={department}
                            onChange={(e) => setDepartment(e.target.value)}
                            variant="brand"
                            required
                        >
                            <option value="">Select</option>
                            {departmentData.map((department) => (
                                <option key={department.department} value={department.department}>
                                    {department.department}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Client</FormLabel>
                        <Select
                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                            variant="brand"
                            required
                        >
                            <option value="">Select</option>
                            {clientData.map((client) => (
                                <option key={client.client_id} value={client.client_id}>
                                    {client.client_name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Task Type</FormLabel>
                        <Select
                            value={taskType}
                            onChange={(e) => setTaskType(e.target.value)}
                            variant="brand"
                            required
                        >
                            <option value="">Select</option>
                            {taskTypeData.map((taskType) => (
                                <option key={taskType.job_type} value={taskType.job_type}>
                                    {taskType.job_type}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Flex>

                <Flex direction={{ base: "column", md: "row" }} gridGap="20px" spacing="4">
                    <FormControl mb={4}>
                        <FormLabel>Task Name</FormLabel>
                        <Input
                            type="text"
                            value={taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                            variant="brand"
                            required
                        />
                    </FormControl>
                </Flex>
                <FormControl mb={4}>
                    <FormLabel>Task Description</FormLabel>
                    <Textarea
                        type="text"
                        value={taskDescription}
                        onChange={(e) => setTaskDescription(e.target.value)}
                        variant="brand"
                        required
                    />
                </FormControl>

                <Flex direction={{ base: "column", md: "row" }} gridGap="20px" spacing="8">
                    <FormControl mb={4}>
                        <FormLabel>Number of Units</FormLabel>
                        <Input
                            type="number"
                            value={numberOfUnits}
                            onChange={(e) => setNumberOfUnits(e.target.value)}
                            variant="brand"
                            required
                        />
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Re-enter Number of Units</FormLabel>
                        <Input
                            type="number"
                            value={reEnterNumberOfUnits}
                            onChange={(e) => setReEnterNumberOfUnits(e.target.value)}
                            variant="brand"
                            required
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Job Priority</FormLabel>
                        <Select
                            value={jobPriority}
                            onChange={(e) => setJobPriority(e.target.value)}
                            variant="brand"
                            required
                        >
                            <option value="">Select</option>
                            {jobPriorityData.map((jobPriority) => (
                                <option key={jobPriority.job_priority} value={jobPriority.job_priority}>
                                    {jobPriority.job_priority}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Flex>
                <Flex direction={{ base: "column", md: "row" }} gridGap="20px">
                    <FormControl display="flex" alignItems="center" mb={4}>
                        <FormLabel mb="0" mr="4">Billable</FormLabel>
                        <Switch
                           isChecked={isBillable === -1} // Check if isBillable is -1
                           onChange={(e) => setIsBillable(e.target.checked ? -1 : 0)} // Set -1 when checked, 0 when unchecked
                            size="lg" // Adjust the size to make it bigger
                            transform="scale(1.2)" // Adjust the scale to make it slightly bigger
                        />
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Billing Type</FormLabel>
                        <Select
                            value={billingType}
                            onChange={(e) => setBillingType(e.target.value)}
                            variant="brand"
                        >
                            <option value="">Select</option>
                            {billingTypeData.map((billingType) => (
                                <option key={billingType.type} value={billingType.type}>
                                    {billingType.type}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Billing Rate</FormLabel>
                        <Input
                            type="number"
                            value={billingRate}
                            onChange={(e) => setBillingRate(e.target.value)}
                            variant="brand"
                        />
                    </FormControl>

                    <FormControl mb={4}>
                  
               
                  <FormLabel>Delivery Date</FormLabel>
                      <DatePicker
                        selected={deliveryDate}
                        onChange={(date) => setDeliveryDate(date)}
                        dateFormat='MM-dd-yyyy'
                        placeholderText={`Select Delivery Date`}
                        isClearable
                        customInput={
                          <Input
                            variant="filled"
                            fontSize="sm"
                            size="md"
                            w="130%"
                            placeholder={`Select Delivery Date`}
                          />
                        }
                      />
            
                </FormControl>
                </Flex>

                <Button mt={4} colorScheme="green" size='md' type="submit">
                    Create Task
                </Button>
            </form>
        </Box>
    );
}

export default CreateTask;
