import React from "react";

// Chakra imports
import { Flex, Image } from "@chakra-ui/react";

// Custom components
// import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import illustration from "assets/img/auth/cropped-logo.png";

export function SidebarBrand() {
  //   Chakra color mode
  return (
    <Flex align='center' direction='column'>
     
      <Image w='200px' h='100px'
    objectFit='contain' src={illustration} />

      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
