import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ chartData: initialChartData, chartOptions }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(initialChartData);
  }, [initialChartData]);

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartData}
      type='pie'
      width='100%'
      height='55%'
    />
  );
};

export default PieChart;
