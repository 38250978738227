import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

import { Link } from "react-router-dom";
import { FaDownload, FaTrash, FaEye } from "react-icons/fa"; // Assuming you want to use FontAwesome icons
import axios from "axios";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";

export default function ComplexTable() {
  const columnsData = [
    {
      Header: "BATCH NUMBER",
      accessor: "batchNumber",
    },
    {
      Header: "SENDER NAME",
      accessor: "SenderName",
    },
    {
      Header: "CURRENCY",
      accessor: "Currency",
    },
    {
      Header: "RECORD COUNT",
      accessor: "RecordCount",
    },
    {
      Header: "ACTIONS",
      accessor: "Actions",
    },
  ];

  const toast = useToast();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isGeneratingLabels, setIsGeneratingLabels] = useState(new Map());

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  // Function to generate labels
  const handleGenerateLabels = async (batchNumber) => {
    try {
      // Set the loading state for the corresponding row to true
      setIsGeneratingLabels((prevLoadingStates) =>
        new Map(prevLoadingStates).set(batchNumber, true)
      );

      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const apiUrl = `${constants.apiUrl}/admin/generate-labels`; // Update to match your API endpoint
      const response = await axios.post(
        apiUrl,
        { batchNumber },
        { headers, responseType: "blob" }
      );

      if (response.status === 200) {
        // PDF generation succeeded, initiate the download
        toast({
          position: "bottom-right",
          title: "Labels Generated Successfully",
          status: "success",
          duration: 3000, // Duration in milliseconds
          isClosable: true, // Whether the toast can be closed by the user
        });

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${batchNumber}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        toast({
          position: "bottom-right",
          title:
            "Label generation failed. Please check your Internet or retry later.",
          status: "error",
          duration: 3000, // Duration in milliseconds
          isClosable: true, // Whether the toast can be closed by the user
        });
        console.error(
          "PDF generation failed. Please check your Internet or retry later."
        );
      }
      // After generating labels, set the loading state back to false
      setIsGeneratingLabels((prevLoadingStates) =>
        new Map(prevLoadingStates).set(batchNumber, false)
      );
      fetchDataForPage(currentPage, pageSize, searchQuery);
      // You can perform any additional actions based on the response
    } catch (error) {
      // Handle errors here
      console.error("Error generating labels:", error.message);
      toast({
        position: "bottom-right",
        title:
          "Label generation failed. Please check your Internet or retry later.",
        status: "error",
        duration: 3000, // Duration in milliseconds
        isClosable: true, // Whether the toast can be closed by the user
      });
      setIsGeneratingLabels((prevLoadingStates) =>
        new Map(prevLoadingStates).set(batchNumber, false)
      );
      fetchDataForPage(currentPage, pageSize, searchQuery);
    }
  };

  // Function to delete a batch by batch number
  const handleDeleteBatch = async (batchNumber) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const apiUrl = `${constants.apiUrl}/admin/delete-batch`; // Replace with the actual URL for deleting a batch
      const response = await axios.delete(apiUrl, {
        headers,
        params: { batchNumber },
      });

      // Handle the response here
      console.log("Batch deleted successfully:", response.data);
      fetchDataForPage(currentPage, pageSize, searchQuery);
      // You can perform any additional actions based on the response
    } catch (error) {
      // Handle errors here
      console.error("Error deleting batch:", error.message);
    }
  };

  const handleReportDownload = async (batchNumber) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const apiUrl = `${constants.apiUrl}/admin/emirates-report-download`;

      // Send a GET request to your Node.js API to download the report
      const response = await axios.get(apiUrl, {
        headers,
        params: { batchNumber },
        responseType: "blob", // Set the response type to 'blob' to handle binary data
      });

      if (response.status === 200) {
        // Create a blob URL to trigger the file download
        const blob = new Blob([response.data], { type: "application/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `emirates_report_${batchNumber}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast({
          position: "bottom-right",
          title: "Report Downloaded Successfully",
          status: "success",
          duration: 3000, // Duration in milliseconds
          isClosable: true, // Whether the toast can be closed by the user
        });
      } else {
        toast({
          position: "bottom-right",
          title:
            "Report Download failed. Please check your Internet or retry later.",
          status: "error",
          duration: 3000, // Duration in milliseconds
          isClosable: true, // Whether the toast can be closed by the user
        });
      }

      // You can perform any additional actions based on the response
    } catch (error) {
      // Handle errors here
      console.error("Error downloading Report:", error.message);
      toast({
        position: "bottom-right",
        title:
          "Report Download failed. Please check your Internet or retry later.",
        status: "error",
        duration: 3000, // Duration in milliseconds
        isClosable: true, // Whether the toast can be closed by the user
      });
    }
  };

  const handleDownloadLabels = async (batchNumber) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const apiUrl = `${constants.apiUrl}/admin/emirates-download-labels`;

      // Send a GET request to your Node.js API to download the report
      const response = await axios.get(apiUrl, {
        headers,
        params: { batchNumber },
        responseType: "blob", // Set the response type to 'blob' to handle binary data
      });

      if (response.status === 200) {
        // Create a blob URL to trigger the file download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${batchNumber}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        toast({
          position: "bottom-right",
          title: "Labels Downloaded Successfully",
          status: "success",
          duration: 3000, // Duration in milliseconds
          isClosable: true, // Whether the toast can be closed by the user
        });
      } else {
        toast({
          position: "bottom-right",
          title:
            "Labels Download failed. Please check your Internet or retry later.",
          status: "error",
          duration: 3000, // Duration in milliseconds
          isClosable: true, // Whether the toast can be closed by the user
        });
      }

      // You can perform any additional actions based on the response
    } catch (error) {
      // Handle errors here
      console.error("Error downloading Report:", error.message);
      toast({
        position: "bottom-right",
        title:
          "Labels Download failed. Please check your Internet or retry later.",
        status: "error",
        duration: 3000, // Duration in milliseconds
        isClosable: true, // Whether the toast can be closed by the user
      });
    }
  };

  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/admin/fetch-table-data`,
        {
          headers,
          withCredentials: true,
          params: {
            page: page,
            pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
          },
        }
      );

      const data = response.data.records;
      setTableData(data);
      setTotalPages(response.data.totalPages);
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      // Handle error if necessary
    } finally {
      setIsDataLoading(false);
    }
  };

  // Fetch data for the initial page

  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchDataForPage(currentPage, pageSize, searchQuery);
    } else if (searchQuery.length === 0) {
      // Reset the search when searchQuery is empty
      fetchDataForPage(currentPage, pageSize);
    }
  }, [currentPage, pageSize, searchQuery]);

  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
      manualPagination: true,
      pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      mt={6}
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Label records
            </Text>
            {/* Search Input */}
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />
          </Flex>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td colSpan={columnsData.length + 1} textAlign="center">
                    No data available.
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, cellIndex) => {
                        let data;

                        if (columns[cellIndex].Header === "ACTIONS") {
                          data = (
                            <>
                              <Flex>
                                {/* Generate Labels button */}
                                {!row.original.allAWBNumberNotNull && (
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() =>
                                      handleGenerateLabels(
                                        row.original.batchNumber
                                      )
                                    }
                                    isDisabled={isGeneratingLabels.get(
                                      row.original.batchNumber
                                    )} // Disable the button while generating labels
                                  >
                                    {isGeneratingLabels.get(
                                      row.original.batchNumber
                                    ) ? (
                                      <>
                                        Generating Labels{" "}
                                        <Spinner size="sm" ml="2" />
                                      </>
                                    ) : (
                                      "Generate Labels"
                                    )}
                                  </Button>
                                )}

                                {row.original.allAWBNumberNotNull && (
                                  <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() =>
                                      handleDownloadLabels(
                                        row.original.batchNumber
                                      )
                                    }
                                  >
                                    {" "}
                                    Download Labels{" "}
                                  </Button>
                                )}

                                {row.original.allAWBNumberNotNull && (
                                  <IconButton
                                    aria-label="Download Report"
                                    icon={<FaDownload />}
                                    colorScheme="blue"
                                    size="sm"
                                    onClick={() =>
                                      handleReportDownload(
                                        row.original.batchNumber
                                      )
                                    }
                                    ml={2}
                                  />
                                )}
                              
                                {/* View button with link */}
                                <Link
                                  to={`/admin/view-batch?batch=${row.original.batchNumber}`}
                                >
                                  <IconButton
                                    aria-label="View"
                                    icon={<FaEye />}
                                    colorScheme="blue"
                                    size="sm"
                                    ml={2}
                                  />
                                </Link>

                                {/* Delete button with API call */}
                                <IconButton
                                  aria-label="Delete"
                                  icon={<FaTrash />}
                                  colorScheme="red"
                                  size="sm"
                                  onClick={() =>
                                    handleDeleteBatch(row.original.batchNumber)
                                  }
                                  ml={2}
                                />
                              </Flex>
                            </>
                          );
                        } else {
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          );
                        }

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            fontSize={{ sm: "14px" }}
                            maxH="30px !important"
                            py="8px"
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1} // Disable the button when on the first page
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the button when there are no previous pages or on the first page
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable the button when there are no next pages or on the last page
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages} // Disable the button when on the last page
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {currentPage} of {totalPages}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
