import React, { useState, useEffect } from "react";
import {
    Box,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Button,
    Flex,
    useToast,
} from "@chakra-ui/react";
import Select from 'react-select';
import { constants } from "constants/constants";
import axios from "axios";
import Card from "components/card/Card";

export default function GenerateNewSwedenInvoice() {
    const [clients, setClients] = useState([]);
    const [batchData, setBatchData] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [customClearance, setCustomClearance] = useState("");
    const [discountPercentage, setDiscountPercentage] = useState("");

    const toast = useToast();

    const getQueryParams = (url) => {
        const queryString = url.split("?")[1];
        if (!queryString) {
            return {};
        }

        const paramsArray = queryString.split("&");
        const params = {};

        paramsArray.forEach(param => {
            const [key, value] = param.split("=");
            params[key] = decodeURIComponent(value);
        });

        return params;
    };

    const windowUrl = window.location.href;

    const queryParams = getQueryParams(windowUrl);
    const batchNumber = queryParams["batchNumber"];

    useEffect(() => {
        fetchClients();
        fetchData();
    }, []);

    const fetchClients = async () => {
        try {

            const token = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const response = await axios.get(`${constants.apiUrl}/admin/fetch-clients`, { headers });
            setClients(response.data);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    };

    const fetchData = async () => {
        try {

            const token = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const response = await axios.get(`${constants.apiUrl}/admin/fetch-batchdata-sweden?batchNumber=${batchNumber}`, { headers });
            setBatchData(response.data);


        } catch (error) {
            console.error("Error fetching batch data:", error);
        }
    };


    // Set invoiceNumber and customClearance if batchData is not empty
    useEffect(() => {
        if (batchData) {
            const { ExistingCustomsClearance, ExistingInvoiceNo, clientName, discountPercentage } = batchData; // Assuming batchData is an array containing objects
            if (ExistingCustomsClearance) {
                setCustomClearance(ExistingCustomsClearance);
            }
            if (ExistingInvoiceNo) {
                setInvoiceNumber(ExistingInvoiceNo);
            }
            if (clientName) {
                setSelectedClient(clientName);
            }
            if (discountPercentage) {
                setDiscountPercentage(discountPercentage);
            }
        }
    }, [batchData]);

    const handleGenerateInvoice = async () => {
        try {


            const token = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${token}`,
            };



            const apiUrl = `${constants.apiUrl}/admin/generate-invoice-sweden`; // Update to match your API endpoint
            const response = await axios.post(
                apiUrl,
                { batchNumber, InvoiceNo: invoiceNumber, CustomsClearance: customClearance, clientName: selectedClient, discountPercentage },
                { headers, responseType: "blob" }
            );

            if (response.status === 200) {
                // PDF generation succeeded, initiate the download
                toast({
                    position: "bottom-right",
                    title: "Invoice Generated Successfully",
                    status: "success",
                    duration: 3000, // Duration in milliseconds
                    isClosable: true, // Whether the toast can be closed by the user
                });

                const blob = new Blob([response.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${batchData.ExpInvoice}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                toast({
                    position: "bottom-right",
                    title:
                        "Invoice generation failed. Please check your Internet or retry later.",
                    status: "error",
                    duration: 3000, // Duration in milliseconds
                    isClosable: true, // Whether the toast can be closed by the user
                });
                console.error(
                    "Invoice generation failed. Please check your Internet or retry later."
                );
            }




            // You can perform any additional actions based on the response
        } catch (error) {
            // Handle errors here
            console.error("Error generating invoice:", error.message);
            toast({
                position: "bottom-right",
                title:
                    "Invoice generation failed. Please check your Internet or retry later.",
                status: "error",
                duration: 3000, // Duration in milliseconds
                isClosable: true, // Whether the toast can be closed by the user
            });

        }
    };
    return (
        <Card
            direction="column"
            w="100%"
            px="0px"
            mt={20}
            overflowX={{ sm: "scroll", lg: "hidden" }}
            display="flex"
            alignItems="center" // Aligning the content in the center vertically
        >
            <SimpleGrid columns={1} spacing={2} width="80%"> {/* Added width="100%" */}
                <FormControl>
                    <FormLabel>Select Client</FormLabel>
                    <Select
                        value={{
                            value: clients.find(client => client.client_name === selectedClient)?.client_id,
                            label: selectedClient
                        }}

                        options={clients.map(client => ({ value: client.client_id, label: client.client_name }))}
                        onChange={option => setSelectedClient(option.label)} // Set the selected client name
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>ExpInvoice No.</FormLabel>
                    <Input
                        value={batchData ? batchData.ExpInvoice : ""}
                        disabled={true}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Enter Invoice Number</FormLabel>
                    <Input
                        value={invoiceNumber}
                        onChange={e => setInvoiceNumber(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Enter Custom Clearance</FormLabel>
                    <Input
                        value={customClearance}
                        onChange={e => setCustomClearance(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Enter Discount Percentage</FormLabel>
                    <Input
                        value={discountPercentage}
                        onChange={e => setDiscountPercentage(e.target.value)}
                    />
                </FormControl>
            </SimpleGrid>
            <Flex justify="flex-end" mt={4} width="100%"> {/* Added width="100%" */}
                <Button colorScheme="blue" onClick={handleGenerateInvoice}>Generate Invoice</Button>
            </Flex>
        </Card>
    );

}
