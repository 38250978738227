import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import ComplexTable from "./components/ComplexTable"; // Replace with the actual path to your ItemTable component

function ViewEmiratesUntrackedBatch() {

  const [isLoading, setIsLoading] = useState(false);


  return (
    <Box>
      <Heading as="h1" mb={4} mt={4}>
        View Batch
      </Heading>
      <hr /> {/* Horizontal line separator */}
      {isLoading ? (
        <Spinner size="lg" mt={4} mb={6}/>
      ) : (
        <>
          <ComplexTable  />
        </>
      )}
    </Box>
  );
}

export default ViewEmiratesUntrackedBatch;
