/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { constants } from "constants/constants";

function Register() {
  const history = useHistory();
  const toast = useToast();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [MintsoftClientCode, setMintsoftClientCode] = useState("");
    const [isCustomer, setIsCustomer] = useState(true); // State for toggling customer or support login

    const handleRegister = () => {

    if (!firstName || !lastName || !email || !password) {
      // Display a toast or any other error handling
      toast({
        position: "bottom-right",
        title: "Please fill all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return; // Stop the registration process
    }

     // Additional validation for Customer with Client Code
     if (isCustomer && !MintsoftClientCode) {
      // Display a toast or any other error handling for missing Client Code
      toast({
        position: "bottom-right",
        title: "Client Code is required for Customers.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return; // Stop the registration process
    }
    const role = isCustomer ? "Customer" : "Support";

      const userData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        role: role
      };

      if(isCustomer){
        userData.mintsoftClientCode = MintsoftClientCode;
      }
  
      axios
        .post(`${constants.apiUrl}/register`, userData) // Make a POST request using Axios
        .then((response) => {
          
          if (response.status === 200) {
            // Registration successful
            console.log("Registration successful");
            toast({
              position: 'bottom-right',
              title: 'Account created.',
              description: "We've created your account for you.",
              status: "success",
              duration: 3000, // Duration in milliseconds
              isClosable: true, // Whether the toast can be closed by the user
            });
            // Redirect to another page if needed
            history.push("/auth/sign-in");
          }  else {
            // Registration failed
            let errorMessage = "Error Creating User";
            if (error.response && error.response.data) {
              errorMessage = error.response.data;
            }
            toast({
              position: "bottom-right",
              title: errorMessage,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.error("Error creating user :", error);
          if(error.response.status == 409){
            toast({
              position: 'bottom-right',
              title: "User with the same email already exists",
              status: "info",
              duration: 3000, // Duration in milliseconds
              isClosable: true, // Whether the toast can be closed by the user
            });
            
          }else{
            toast({
              position: 'bottom-right',
              title: "Error creating user" + error,
              status: "error",
              duration: 3000, // Duration in milliseconds
              isClosable: true, // Whether the toast can be closed by the user
            });
          }
     
        });
    };
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "12px" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign Up
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter below details to sign Up!
          </Text>
        </Box>
      {/* Toggle between Customer and Support login */}
      <Flex
          alignItems="center"
          justifyContent="space-between"
          borderRadius="15px"
          borderWidth="1px"
          borderColor="brand.500"
          p="2px"
          mb="20px" // Padding to create a space between the border and switch
        >
          <Box
            as="button"
            p="8px"
            flex="1"
            textAlign="center"
            fontWeight="500"
            fontSize="sm"
            bg={isCustomer ? "brand.500" : "transparent"}
            color={isCustomer ? "white" : "brand.500"}
            borderRadius="14px 0 0 14px"
            outline="none"
            onClick={() => setIsCustomer(true)}
            cursor="pointer"
          >
            Customer
          </Box>
          <Box
            as="button"
            p="8px"
            flex="1"
            textAlign="center"
            fontWeight="500"
            fontSize="sm"
            bg={!isCustomer ? "brand.500" : "transparent"}
            color={!isCustomer ? "white" : "brand.500"}
            borderRadius="0 14px 14px 0"
            outline="none"
            onClick={() => setIsCustomer(false)}
            cursor="pointer"
          >
            Support
          </Box>
        </Flex>


        {isCustomer && (
          // Show the Mintsoft Client Code field for Customer login only
          <FormControl>
            <FormLabel
             display='flex'
             ms='4px'
             fontSize='sm'
             fontWeight='500'
             color={textColor}
             mb='8px'
            >
              Client Code<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={isCustomer}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Your Client Code"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setMintsoftClientCode(e.target.value)}
            />
          </FormControl>
        )}
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
             {/* First Name and Last Name in one row */}
        <Flex direction={{ base: "column", md: "row" }} gridGap="20px">
          <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              First Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='John'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(e) => setFirstName(e.target.value)}
            />
               <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Last Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Doe'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(e) => setLastName(e.target.value)}
            />
            </Flex>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleRegister}
              >
              Sign Up
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Register;
