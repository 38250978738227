import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdAssignment,
  MdPeople,
  MdHeadsetMic, 
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import CustomerList from "views/admin/customerList";
import SupportList from "views/admin/supportList";
import AddSupportMember from "views/admin/addSupportMember";
import EditMember from "views/admin/editMember";
import GenerateEmiratesLabels from "views/admin/generateEmiratesLabels";
import ViewEmiratesBatch from "views/admin/viewEmiratesBatch";
import GenerateInvoice from "views/admin/generateInvoice";
import EmiratesLogs from "views/admin/emiratesLogs";

//Starlinks
import GenerateStarlinksLabels from "views/admin/generateStarlinksLabels";
import ViewStarlinksBatch from "views/admin/viewStarlinksBatch";
import UploadStarlinksManifest from "views/admin/uploadStarlinksManifest";

//NordPost 
import GenerateNordPostLabels from "views/admin/generateNordPostLabels";
import ViewNordPostBatch from "views/admin/viewNordPostBatch";
import NordPostLogs from "views/admin/nordPostLogs";
import UploadNordPostManifest from "views/admin/uploadNordPostMainfest";
import ViewNordPostManifestBatch from "views/admin/viewNordPostManifestBatch";
//Customer Imports
import Claims from "views/customer/claims";

//Support Imports
import CreateTask from "views/support/createTask";
import TaskList from "views/support/taskList";
import ViewJob from "views/support/viewJob";
import JobInvoice from "views/support/jobInvoice";
import ProfitablityReport from "views/support/profitabilityReport";
import ViewJobProfit from "views/support/viewJobProfit";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Register from "views/auth/register";
import ForgotPassword from "views/auth/forgotPassword";
import ResetPassword from "views/auth/resetPassword";
import GenerateNewInvoice from "views/admin/generateNewInvoice";
import AddClient from "views/admin/addClient";
import GenerateBPOSTInvoice from "views/admin/generateBpostInvoice";
import EditClient from "views/admin/editClient";
import GenerateNewBPOSTInvoice from "views/admin/generateNewBPostInvoice";
import GenerateSwedenInvoice from "views/admin/generateSwedenInvoice";
import GenerateNewSwedenInvoice from "views/admin/generateNewSwedenInvoice";
import GenerateEmiratesUntrackedLabels from "views/admin/generateEmiratesUntrackedLabels";
import ViewEmiratesUntrackedBatch from "views/admin/viewEmiratesUntrackedBatch";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    accessRole: "Admin",
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  //   hideInMenu: true,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    hideInMenu: true,
  },
  {
    name: "Register",
    layout: "/auth",
    path: "/register",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Register,
    hideInMenu: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ForgotPassword,
    hideInMenu: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ResetPassword,
    hideInMenu: true,
  },
  {
    name: "Claims",
    layout: "/customer",
    path: "/claims",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: Claims,
    accessRole: "Customer",
  },
  {
    name: "Customers",
    layout: "/admin",
    path: "/customer-list",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    component: CustomerList,
    accessRole: "Admin",
  },
  {
    name: "Support Team",
    layout: "/admin",
    path: "/support-team-list",
    icon: <Icon as={MdHeadsetMic} width='20px' height='20px' color='inherit' />,
    component: SupportList,
    accessRole: "Admin",
  },
  {
    name: "Edit Member",
    layout: "/admin",
    path: "/edit-member",
    component: EditMember,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Add Support Member",
    layout: "/admin",
    path: "/add-support-member",
    icon: <Icon as={MdHeadsetMic} width='20px' height='20px' color='inherit' />,
    component: AddSupportMember,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Generate Emirates Label",
    layout: "/admin",
    path: "/generate-label",
    component: GenerateEmiratesLabels,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Generate Emirates Untracked Label",
    layout: "/admin",
    path: "/generate-untracked-label",
    component: GenerateEmiratesUntrackedLabels,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "View Emirates Untracked Batch",
    layout: "/admin",
    path: "/view-emirates-untracked-batch",
    component: ViewEmiratesUntrackedBatch,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Create Job",
    layout: "/support",
    path: "/create-task",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: CreateTask,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Generate Starlinks Label",
    layout: "/admin",
    path: "/generate-starlinks-label",
    component: GenerateStarlinksLabels,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Upload Starlinks Manifest",
    layout: "/admin",
    path: "/upload-starlinks-manifest",
    component: UploadStarlinksManifest,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "View Starlinks Batch",
    layout: "/admin",
    path: "/view-starlinks-batch",
    component: ViewStarlinksBatch,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "View Emirates Batch",
    layout: "/admin",
    path: "/view-batch",
    component: ViewEmiratesBatch,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Generate Invoice",
    layout: "/admin",
    path: "/generate-invoice",
    component: GenerateInvoice,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Emirates API logs",
    layout: "/admin",
    path: "/emrrates-logs",
    component: EmiratesLogs,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "NordPost API logs",
    layout: "/admin",
    path: "/nord-post-logs",
    component: NordPostLogs,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Generate NordPost Label",
    layout: "/admin",
    path: "/generate-nord-post-label",
    component: GenerateNordPostLabels,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "View NordPost Batch",
    layout: "/admin",
    path: "/view-nord-post-batch",
    component: ViewNordPostBatch,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Upload NordPost Manifest",
    layout: "/admin",
    path: "/upload-nord-post-manifest",
    component: UploadNordPostManifest,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "View NordPost Manifest Batch",
    layout: "/admin",
    path: "/view-nord-post-manifest",
    component: ViewNordPostManifestBatch,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Generate New Invoice",
    layout: "/admin",
    path: "/new-invoice-create",
    component: GenerateNewInvoice,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Add Client",
    layout: "/admin",
    path: "/add-client",
    component: AddClient,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Edit Client",
    layout: "/admin",
    path: "/edit-client",
    component: EditClient,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Generate BPOST Invoice",
    layout: "/admin",
    path: "/generate-bpost-invoice",
    component: GenerateBPOSTInvoice,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Generate NEW BPOST Invoice",
    layout: "/admin",
    path: "/new-bpost-invoice-create",
    component: GenerateNewBPOSTInvoice,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Generate Sweden Invoice",
    layout: "/admin",
    path: "/generate-sweden-invoice",
    component: GenerateSwedenInvoice,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Generate NEW Sweden Invoice",
    layout: "/admin",
    path: "/new-sweden-invoice-create",
    component: GenerateNewSwedenInvoice,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Emirates Labels",
    layout: "/admin",
    path: "/emirates-labels",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Generate Emirates Label",
        layout: "/admin",
        path: "/generate-label",
        component: GenerateEmiratesLabels,
        accessRole: "Admin",
      },
      {
        name: "Generate Emirates Untracked Label",
        layout: "/admin",
        path: "/generate-untracked-label",
        component: GenerateEmiratesUntrackedLabels,
        accessRole: "Admin",
      },
      {
        name: "Generate Invoice",
        layout: "/admin",
        path: "/generate-invoice",
        component: GenerateInvoice,
        accessRole: "Admin",
      },
      {
        name: "Emirates API logs",
        layout: "/admin",
        path: "/emrrates-logs",
        component: EmiratesLogs,
        accessRole: "Admin",
      },
    
    ],
    accessRole: "Admin",
  },
  {
    name: "Starlinks Labels",
    layout: "/admin",
    path: "/starlinks-labels",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Generate Starlinks Label",
        layout: "/admin",
        path: "/generate-starlinks-label",
        component: GenerateStarlinksLabels,
        accessRole: "Admin",
      },
      {
        name: "Upload Starlinks Manifest",
        layout: "/admin",
        path: "/upload-starlinks-manifest",
        component: UploadStarlinksManifest,
        accessRole: "Admin",
      },
    ],
    accessRole: "Admin",
  },
  {
    name: "NordPost Labels",
    layout: "/admin",
    path: "/nord-post-labels",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Generate NordPost Label",
        layout: "/admin",
        path: "/generate-nord-post-label",
        component: GenerateNordPostLabels,
        accessRole: "Admin",
      },{
        name: "NordPost API logs",
        layout: "/admin",
        path: "/nord-post-logs",
        component: NordPostLogs,
        accessRole: "Admin",
      },
      {
        name: "Upload NordPost Manifest",
        layout: "/admin",
        path: "/upload-nord-post-manifest",
        component: UploadNordPostManifest,
        accessRole: "Admin",
      },
    ],
    accessRole: "Admin",
  },
  {
    name: "Generate Invoices",
    layout: "/admin",
    path: "/generate-invoices",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Generate Emirates Invoice",
        layout: "/admin",
        path: "/generate-invoice",
        component: GenerateInvoice,
        accessRole: "Admin",
      },
      {
        name: "Generate BPOST Invoice",
        layout: "/admin",
        path: "/generate-bpost-invoice",
        component: GenerateBPOSTInvoice,
        accessRole: "Admin",
      },
      {
        name: "Generate Sweden Invoice",
        layout: "/admin",
        path: "/generate-sweden-invoice",
        component: GenerateSwedenInvoice,
        accessRole: "Admin",
      },
    
    ],
    accessRole: "Admin",
  },
];

export default routes;
