import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import ItemTable from "./components/ComplexTable"; // Replace with the actual path to your ItemTable component

function GenerateLabels() {
  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (event) => {
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("csvFile", file);

      setIsLoading(true);

      axios.post(`${constants.apiUrl}/admin/upload-csv-starlinks`, formData, {headers, withCredentials: true})
        .then((response) => {
          setIsLoading(false);
          // Handle successful response, if needed
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("CSV upload error:", error);
        });
    }
  };


  return (
    <Box>
      <Heading as="h1" mb={4} mt={4}>
        CSV Upload and Display
      </Heading>
      <Flex flexDirection="row" alignItems="center">
        <FormControl flex="1">
          <FormLabel>Upload CSV File</FormLabel>
          <Input type="file" accept=".csv" onChange={handleFileUpload}/>
        </FormControl>
      </Flex>
      <hr /> {/* Horizontal line separator */}
      {isLoading ? (
        <Spinner size="lg" mt={4} mb={6}/>
      ) : (
        <>
          <ItemTable  />
        </>
      )}
    </Box>
  );
}

export default GenerateLabels;
