import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, Icon, useColorModeValue } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

export function SidebarLinks(props) {
  const { routes } = props;
  const location = useLocation();
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
  const activeIcon = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const [openCategory, setOpenCategory] = useState("");
  const [activeSubItemPath, setActiveSubItemPath] = useState(""); // Keep track of the active sub-item path

  useEffect(() => {
    const matchingSubItem = routes.find(
      (route) => route.path && location.pathname.includes(route.path)
    );
  
    if (matchingSubItem) {
      const matchingCategory = findOpenCategory(matchingSubItem.layout + matchingSubItem.path);
      
      // Check if the matching category is already open before updating
      if (matchingCategory !== openCategory) {
        setOpenCategory(matchingCategory);
      }
      
      setActiveSubItemPath(matchingSubItem.layout + matchingSubItem.path);
    }
  }, [location.pathname, routes]); // Add openCategory to the dependency array
  
  
  const findOpenCategory = (subItemPath) => {
    for (const route of routes) {
      if (route.category && route.items.some(item => subItemPath.includes(item.path))) {
        return route.name;
      }
    }
    return "";
  };

  const handleCategoryClick = (categoryName) => {
    setOpenCategory(categoryName === openCategory ? "" : categoryName);
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        const isActiveCategory = route.name === openCategory;
        const isActiveSubItem = route.layout + route.path === activeSubItemPath;
      
        if (isActiveSubItem) {
          setOpenCategory(route.category);
        }

        return (
          <Box key={index}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <Box
                color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                me='18px'
              >
                {route.icon}
              </Box>
              <Text
                fontSize="md"
                color={isActiveCategory ? activeColor : textColor}
                fontWeight={isActiveCategory ? "bold" : "normal"}
                ps={{ sm: "10px", xl: "16px" }}
                py="18px"
                onClick={() => handleCategoryClick(route.name)} // Toggle the category on click
              >
                {route.name}
              </Text>
            </Flex>
            <Icon as={isActiveCategory ? ChevronDownIcon : ChevronRightIcon} color={activeColor} />
          </Flex>
          {isActiveCategory && createLinks(route.items)}
        </Box>
        
        );
      }else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/customer" ||
        route.layout === "/support" ||
        route.layout === "/operations"
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon ? (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='18px'>
                      {route.icon}
                    </Box>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }>
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
