import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Flex, Spinner } from '@chakra-ui/react'; // Import the Flex and Spinner components
import axios from 'axios';
import { constants } from 'constants/constants';
import { useHistory ,useLocation } from 'react-router-dom';

const PrivateRoute = ({ component: Component, requiredRole, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState([]); // State to hold the user's role
  // Use the location hook to listen for location changes
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    // Get the token from local storage or wherever you store it
    const token = localStorage.getItem('accessToken');
    // Call the API endpoint on the backend to validate the token
    axios
      .post(`${constants.apiUrl}/validate-token`, { token }, { withCredentials: true })
      .then((response) => {
        // Backend returned a successful response, token is valid
        if (response.status === 200) {
          const roleArr = response.data.role.split(",");
          setUserRole(roleArr); // Set the user's role in state

          setIsAuthenticated(true);
          
        }
     
        setIsLoading(false); // Set loading state to false once validation is complete
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Clear token and redirect to login
          localStorage.removeItem('accessToken');
          history.push('/auth/sign-in');
        }
        // Backend returned an error response, token is invalid or expired
        setIsAuthenticated(false);
        setIsLoading(false); // Set loading state to false once validation is complete
      });
  }, [location]);

  console.log(isAuthenticated);
// Check if the user is authenticated and has the required role
const isAuthorized = isAuthenticated && userRole.includes(requiredRole);

if(!isAuthorized){
  <Redirect to="/auth/sign-in" />
}
  // Show loading spinner while validating the token
  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" thickness="4px" color="green.500" />
      </Flex>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized ? <Component {...props} /> : <Redirect to="/auth/sign-in" />
      }
    />
  );
};

export default PrivateRoute;
